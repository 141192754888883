//po

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewIcon from "@mui/icons-material/Preview";
import SearchIcon from "@mui/icons-material/Search";
import { saveAs } from "file-saver";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const Po_acceptanceList = () => {
  const [opTableData, setOpTableData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "PO Number");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = opTableData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getPoTableData();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("PO Number");
    setSearchKey("");
    setSearched(false); 
  };

  const getPoTableData = () => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "PO Number": "poNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` po.poDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT po.quotationDate, po.quotationId, qt.quotationNumber, po.poFileUrl, po.poNumber, po.poDate, po.amount, po.id, addr.address, cl.companyName FROM poAcceptance AS po LEFT JOIN quotation AS qt ON qt.id = po.quotationId LEFT JOIN (SELECT id, SUBSTRING_INDEX(address, ',', 1) AS address_id FROM quotation) AS qt_addresses ON qt.id = qt_addresses.id LEFT JOIN addresses AS addr ON qt_addresses.address_id = addr.id LEFT JOIN clients AS cl ON qt.clientId = cl.id  ${whereConditions} order by po.id DESC`,
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, data)
      .then((res) => {
        setOpTableData(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getPoTableData" ,"Po_acceptanceList")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `poAcceptance/${id}`)
      .then((res) => {
        getPoTableData();
        toast("Po_Acceptance  was deleted!");
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"Po_acceptanceList")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const handleDownload = (row) => {
    if (row.poFileUrl) {
      axiosWithToken({
        url: row.poFileUrl,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          saveAs(blob, "PoFile");
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error(<h6 onClick={() => openErrorModal(err.message, "handleDownload" ,"Po_acceptanceList")}><u>Error: {err.message}</u></h6>);
          }
        });
    } else {
      toast.error("No file uploaded to download.");
    }
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2}>
      <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          value={searchBy}
          options={[{ key: "poNumber", label: "PO Number" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
      <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
          sx={{ marginLeft: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
      <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            searched ? clearSearch() : search();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    if(searched==false){
      getPoTableData();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  return (
    <div>
      {filterModal()}
      <Box sx={{ display: "flex", gap: "0rem" }}>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to="/poAcceptance/create"
          >
            <b>create po</b>
          </Button>
        </Toolbar>
      
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
              </Box>
              {!isMobile && filterSection()}
      
      <br />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Sr.No</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Client Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Quotation Number
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Quotation Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>PO Number</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>PO Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                 
                  <TableCell style={{ minWidth: 130 }}>
                    {row.companyName}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {row.address}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {row.quotationNumber}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {moment(row.quotationDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ minWidth: 130 }}>
                    {row.poNumber}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>
                    {moment(row.poDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>{row.amount}</TableCell>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit PO" placement="top-start">
                        <Button
                          component={Link}
                          to={`/poAcceptance/edit/${row.id}`}
                        >
                          <EditIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View File" placement="top-start">
                        <Button
                          component={Link}
                          to={row.poFileUrl}
                          target="_blank"
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Download File" placement="top-start">
                        <Button onClick={() => handleDownload(row)}>
                          <DownloadIcon />
                        </Button>
                      </Tooltip>
                      {showComponent("delete") && (
                        <Tooltip title="Delete PO" placement="top-start">
                          <Button
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}

                            onClick={(e) => {
                              if (window.confirm("Really want to delete PO?")) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={opTableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </div>
  );
};

export default Po_acceptanceList;

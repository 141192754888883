export const mode = ["Source", "Measure"];

export const materialTypeOp = [
  "select material type",
  "steel",
  "carbide",
  "Cast Iron",
  "Plastic",
];

export const modalConfigs = [
  {
    buttonLabel: "Range",
    transformedStringLabel: "masterrange",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "masteraccuracy",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "masterleastcount",
  },
];

export const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
    "power",
    "power factor",
    "AC High voltage",
    "DC High voltage",
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
    "power",
    "power factor",
    "AC High voltage",
    "DC High voltage",
  ],
};

export const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
    ],
  },
};

export const readingColumns = [
  [
    {
      field: "action",
      headerName: "Action",
      editable: false,
    },
    {
      field: "id",
      headerName: "Sr.No.",
      editable: false,
    },
    {
      field: "from_range",
      headerName: "Cal.Lower Point",
      editable: true,
    },
    {
      field: "to_range",
      headerName: "Cal.Higher Point",
      editable: true,
    },
    {
      field: "otherParameter",
      headerName: "Parameter",
      editable: true,
    },
    {
      field: "mode",
      headerName: "Mode",
      editable: true,
      toggle: "plus",
    },
    {
      field: "parameter",
      headerName: "Parameter",
      editable: true,
      toggle: "plus",
    },
    {
      field: "paratype",
      headerName: "Parameter Type",
      editable: true,
      toggle: "plus",
    },
    {
      field: "uncertainty",
      headerName: "Uncertainty",
      editable: true,
    },
    {
      field: "accuracy",
      headerName: "Accuracy",
      editable: true,
    },
    {
      field: "percentOfOutput",
      headerName: "% Of Output",
      editable: true,
      toggle: "plus",
    },
    {
      field: "plusValue",
      headerName: "+ Value",
      editable: true,
      toggle: "plus",
    },
    {
      field: "stability",
      headerName: "Stability",
      editable: true,
    },
    {
      field: "uniformity",
      headerName: "Uniformity",
      editable: true,
    },
    {
      field: "axialUniformity",
      headerName: "AxialUniformity",
      editable: true,
    },
    {
      field: "radialUniformity",
      headerName: "RadialUniformity",
      editable: true,
    },

    {
      field: "reaction",
      headerName: "Master Temperature",
      editable: true,
    },
    {
      field: "gravity",
      headerName: "Least Count",
      editable: true,
    },
    {
      field: "drift",
      headerName: "Drift",
      editable: true,
    },
    {
      field: "cmv",
      headerName: "Conventional Mass Value",
      editable: true,
      toggle: "mnv",
    },
    {
      field: "mpe",
      headerName: "MPE",
      editable: true,
      toggle: "mnv",
    },
    {
      field: "materialDensity",
      headerName: "Material density",
      editable: true,
      toggle: "mnv",
    },
  ],
];

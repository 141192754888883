import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { productListGuide } from "./guide";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  { id: "companyName", label: "Client", minWidth: 40 },
  { id: "instrumentName", label: "Product Name", minWidth: 40 },
  { id: "rate", label: "Rate", minWidth: 40 },
];

function createData(id, instrumentId, rate) {
  return {
    id,
    instrumentId,
    rate,
  };
}

export default function ProductsList() {
  const productListGuides = productListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const urlPath = useLocation();
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Product Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [productsList, setProductList] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [rowDaata, setRowData] = React.useState([]);
  const [pushIArray, setPushIArray] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitProductDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `customProducts/${id}`)
      .then((res) => {
        toast("products deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitProductDelete" ,"Product List")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getProductList = (event) => {
    let url = BASE_URL;

    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        Client: "c.companyName",
        "Product Name": "i.instrumentName",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    const payload1 = {
      query: `SELECT cp.*, i.instrumentName, c.companyName  FROM customProducts cp  LEFT JOIN clients c ON cp.clientId = c.id LEFT JOIN instruments i ON cp.instrumentId = i.id ${whereConditions} ORDER BY cp.id DESC`,
    };

    axiosWithToken
      .post(url + "dynamic", payload1)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitProductDelete" ,"Product List")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const copyEquipment = async (id) => {
    let instrument = await axiosWithToken
      .get(BASE_URL + `customProducts/${id}`)
      .then((res) => res.data[0] || []);
    delete instrument.id;

    axiosWithToken.post(BASE_URL + "customProducts", instrument).then((res) => {
      setTimeout(refresh, 500)
    });
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getProductList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Product Name");
    setSearchKey("");
    setSearched(false); 
  };

  useEffect(() => {
    if (searched==false) {
    getProductList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="client_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          options={[
            { key: "i.instrumentId", label: "Product Name" },
            { key: "c.companyName", label: "Client" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          value={searchKey}
          size="small"
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="client_list_searchbtn"
          variant="contained"
          size="small"
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="product_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/product/createProduct"
          >
            <b>create PRODUCT</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                productListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          id="product_list_table"
          stickyHeader
          aria-label="sticky table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                         {value != null && value !== "" ? (
                          column.format && typeof value === "number"
                            ? ( column.format(value) )
                            : ( value )
                            ) : (
                              "NA"
                            )}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Product" placement="top-start">
                          <Button
                            id="product_list_edit"
                          
                            component={Link}
                            to={`/master/editProduct/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Copy Product" placement="top-start">
                          <Button
                            disabled={!(editAccess?.includes(5) || editAccess?.includes(0))}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Do want to copy and create new Product with rate?"
                                )
                              ) {
                                copyEquipment(row.id);
                              }
                            }}
                          >
                            
                            <ContentCopyIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Product" placement="top-start">
                          <Button
                            id="product_list_delete"
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete product?")
                              ) {
                                submitProductDelete(row.id);
                              }
                            }}
                            style={{
                              borderColor:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545'
                            }}
                          >
                            <DeleteIcon style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={productsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />

      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Box>
  );
}

import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Toolbar,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { editProductGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";


export default function EditProduct(props) {
  const editproductGuides = editProductGuide();
  const [rate, setRate] = React.useState("");
  const [instrumentId, setInstrumentId] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [clientId, setClientId] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const params = useParams();
  const getProductIdList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `customProducts/${params.id}`)
      .then((res) => {
        setInstrumentId(res.data[0].instrumentId);
        setRate(res.data[0].rate);
        setClientId(res.data[0]?.clientId);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getProductIdList", "Edit Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getInstrumentsList", "Edit Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            // contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getCustomer", "Edit Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  useEffect(() => {
    getProductIdList();
    getInstrumentsList();
    getCustomer();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      // productName: finalProductName,
      rate: rate,
    };
    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };
    axiosWithToken
      .patch(url + `customProducts/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("updated Products!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        // props.setLoader(false);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "onSubmit", "Edit Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Edit Product
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editproductGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        {clientId !== 0 && (
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField
              id="product_edit_compnay"
              label="Client"
              size="small"
              disabled
              value={
                (clientList.find((e) => e.id === clientId) || {}).label || ""
              }
              fullWidth
              variant="outlined"
              onChange={(event, value) => {
                if (value === null) {
                  setClientId(0);
                } else {
                  setClientId(value.id);
                }
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="product_edit_instruname"
            label="Product Name *"
            size="small"
            disabled
            value={
              instrumentArray.find((lab) => lab.id === instrumentId)
                ?.instrumentName || ""
            }
            fullWidth
            variant="outlined"
            onChange={(e) => setInstrumentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="product_edit_rate"
            label="Rate *"
            size="small"
            value={rate}
            fullWidth
            variant="outlined"
            onChange={(e) => setRate(e.target.value)}
          />
        </Grid>
      </Grid>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          id="product_edit_save"
          variant="contained"
          size="small"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          update
        </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </Paper>
  );
}

export const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Master",
  },
  {
    id: 51,
    name: "Master EQP",
  },
  {
    id: 52,
    name: "Instrument",
  },
  {
    id: 53,
    name: "Duc Master",
  },
  {
    id: 54,
    name: "Discipline",
  },
  {
    id: 55,
    name: "Remark",
  },
  {
    id: 57,
    name: "Client",
  },
  {
    id: 58,
    name: "Units",
  },
  {
    id: 59,
    name: "Table Template",
  },
  {
    id: 60,
    name: "Datasheet Template",
  },
  {
    id: 61,
    name: "Uncertainty",
  },
  {
    id: 62,
    name: "Settings",
  },
  {
    id: 3,
    name: "SRF",
  },
  {
    id: 4,
    name: "Datasheets",
  },
  {
    id: 5,
    name: "Certificates",
  },
  {
    id: 7,
    name: "Courier Register",
  },
  {
    id: 8,
    name: "Expenses",
  },
  {
    id: 9,
    name: "Work Report",
  },
  {
    id: 10,
    name: "Summary Report",
  },
  {
    id: 11,
    name: "History Card",
  },
  {
    id: 12,
    name: "Scheduler",
  },
  {
    id: 14,
    name: "Commercial",
  },
  {
    id: 17,
    name: "Payment",
  },
  {
    id: 21,
    name: "Master In/Out",
  },
  {
    id: 25,
    name: "User",
  },
  {
    id: 27,
    name: "Audit",
  },
  {
    id: 28,
    name: "Client Complaints",
  },
  {
    id: 29,
    name: "Client Feedback",
  },
  {
    id: 30,
    name: "Request Form",
  },
  {
    id: 31,
    name: "Document Upload",
  },
  {
    id: 32,
    name: "Help",
  },
  {
    id: 33,
    name: "Attendance",
  },
  {
    id: 34,
    name: "MarkAttendance",
  },
  {
    id: 35,
    name: "Reports",
  },
  {
    id: 36,
    name: "WCR Report",
  }
];

export const userDesignation = [
  {
    id: 1,
    name: "Calibration Engineer",
  },
  {
    id: 2,
    name: "Senior Engineer",
  },
  {
    id: 3,
    name: "Senior Calibration Engineer",
  },
  {
    id: 4,
    name: "Quality Manager",
  },
  {
    id: 5,
    name: "Technical Manager",
  },
  {
    id: 6,
    name: "Technical Head",
  },
  {
    id: 7,
    name: "Lab Head",
  },
  {
    id: 8,
    name: "Asst. Technical Manager",
  },
  {
    id: 9,
    name: "Cheaf Executive Director",
  },
  {
    id: 10,
    name: "Junior Accountant",
  },
  {
    id: 11,
    name: "Senior Accountant",
  },
  {
    id: 12,
    name: "Account Head",
  },
  {
    id: 13,
    name: "Lab Incharge",
  },
  {
    id: 14,
    name: "Executive Director-Technical",
  },
  {
    id: 15,
    name: "Testing Engineer",
  },
  {
    id: 16,
    name: "Senior Testing Engineer",
  },
  {
    id: 17,
    name: "Lab Assistance",
  },
];

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },
  {
    id: 4,
    label: "Branch Admin",
  },
  {
    id: 2,
    label: "Engineer",
  },
  {
    id: 3,
    label: "Client",
  },
  {
    id: 5,
    label: "Lab Co-Ordinator",
  },
  {
    id: 6,
    label: "Account Dept",
  },
];

export const rolesArray = [
  {
    name: "Calibration Authority ",
    id: "1",
  },
  {
    name: "Approved Authority",
    id: "2",
  },
  ,
  {
    name: "Reviewed By",
    id: "3",
  },
  ,
  {
    name: "Signed By",
    id: "4",
  },
  ,
  {
    name: "Witnessed By",
    id: "5",
  },
];

export const headers = [
  { name: "Client" },
  { name: "Address" },
  { name: "Start Date" },
  { name: "End Date" },
];

export const editAccessOptions = [
  {
    id: 0,
    label: "All Access",
  },
  {
    id: 1,
    label: "Edit",
  },
  {
    id: 2,
    label: "Delete",
  },
  {
    id: 3,
    label: "PDF Download",
  },
  {
    id: 4,
    label: "Excel Download",
  },
  {
    id: 5,
    label: "Copy Data",
  },
  {
    id: 6,
    label: "None",
  },
]

import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { createProductGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";

export default function CreateProduct(props) {
  const navigate = useNavigate();
  const createproductGuides = createProductGuide();
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [prRate, setPrRate] = React.useState("");
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [clientList, setClientList] = React.useState([]);
  const [clientId, setClientId] = React.useState(0);

  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    let data = {
      query: `SELECT instruments.id, instruments.instrumentName FROM instruments LEFT JOIN customProducts ON instruments.id = customProducts.instrumentId AND customProducts.clientId = ${clientId} WHERE instruments.status = 1 AND customProducts.instrumentId IS NULL`,
      //AND customProducts.instrumentId IS NULL
    };
    axiosWithToken
      .post(url + "dynamic", data)
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "getInstrumentsList",
                  "Create Product"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  useEffect(() => {
    getInstrumentsList();
  }, [clientId]);

  var refresh = () => {
    window.location.reload(false);
    navigate("/master/product");
  };

  const saveProduct = (event) => {
    let row = {
      clientId: clientId,
      rate: prRate,
      instrumentId: instrumentId,
    };

    let url = BASE_URL;

    axiosWithToken
      .post(url + "customProducts", row)
      .then((res) => {
        props.setLoader(false);
        toast("custom product created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        // props.setLoader(false);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "saveProduct", "Create Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  const getCustomer = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            // contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getCustomer", "Create Product")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create Product 
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createproductGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Autocomplete
            size="small"
            id="product_create_company"
            options={clientList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Choose Client" />
            )}
            onChange={(event, value) => {
              if (value === null) {
                setClientId(0);
              } else {
                setClientId(value.id);
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Autocomplete
            size="small"
            id="product_create_instruname"
            options={pushIArray}
            getOptionLabel={(option) => option.label}
            // value={invoiceObject.companyName}
            renderInput={(params) => (
              <TextField {...params} label="Choose an Instrument *" />
            )}
            onChange={(event, value) => {
              let id = value?.label?.split(",")[0];
              let name = value?.label?.split(",")[1];
              //  setPrName(name)
              setInstrumentId(id);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="product_create_rate"
            label="Rate *"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPrRate(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
        <Button
          id="product_create_save"
          variant="contained"
          size="small"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            props.setLoader(true);
            saveProduct();
          }}
        >
          Save
        </Button>
        </Tooltip>
      </Toolbar>
      <ToastContainer />

      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </Paper>
  );
}

export const symbolList = [
  {
    symbol: "rad",
    name: "radian",
    type: "predefined",
  },
  {
    symbol: "deg",
    name: "degree",
    type: "predefined",
  },
  {
    symbol: "grad",
    name: "gradian",
    type: "predefined",
  },
  {
    symbol: "arcmin",
    name: "arcminute",
    type: "predefined",
  },
  {
    symbol: "arcsec",
    name: "arcsecond",
    type: "predefined",
  },
  {
    symbol: "RPM",
    name: "radian",
    type: "predefined",
  },
  {
    symbol: "VA",
    name: "Volt-Ampere",
    type: "predefined",
  },
  {
    symbol: "mVA",
    name: "Millivolt-Ampere",
    type: "predefined",
  },
  {
    symbol: "kVA",
    name: "Kilovolt-Ampere",
    type: "predefined",
  },
  {
    symbol: "MVA",
    name: "Megavolt-Ampere",
    type: "predefined",
  },
  {
    symbol: "GVA",
    name: "Gigavolt-Ampere",
    type: "predefined",
  },
  {
    symbol: "mm2",
    name: "Square Millimeter",
    type: "predefined",
  },
  {
    symbol: "cm2",
    name: "Centimeter",
    type: "predefined",
  },
  {
    symbol: "m2",
    name: "Square Meter",
    type: "predefined",
  },
  {
    symbol: "ha",
    name: "Hectare",
    type: "predefined",
  },
  {
    symbol: "km2",
    name: "Square Kilometer",
    type: "predefined",
  },
  {
    symbol: "in2",
    name: "Square Inch",
    type: "predefined",
  },
  {
    symbol: "yd2",
    name: "Square Yard",
    type: "predefined",
  },
  {
    symbol: "ft2",
    name: "Square Foot",
    type: "predefined",
  },
  {
    symbol: "ac",
    name: "Acre",
    type: "predefined",
  },
  {
    symbol: "mi2",
    name: "Square Mile",
    type: "predefined",
  },
  {
    symbol: "A",
    name: "Ampere",
    type: "predefined",
  },
  {
    symbol: "mA",
    name: "Milliampere",
    type: "predefined",
  },
  {
    symbol: "µA",
    name: "Microampere",
    type: "predefined",
  },
  {
    symbol: "kA",
    name: "Kiloampere",
    type: "predefined",
  },
  {
    symbol: "kgmc",
    name: "Kilogram per Cubic Meter",
    type: "predefined",
  },
  {
    symbol: "gmc",
    name: "Gram per Cubic Meter",
    type: "predefined",
  },
  {
    symbol: "g/ml",
    name: "Gram per Milliliter",
    type: "predefined",
  },
  {
    symbol: "mgml",
    name: "Milligram per Milliliter",
    type: "predefined",
  },
  {
    symbol: "lbft3",
    name: "Pound per Cubic Foot",
    type: "predefined",
  },
  {
    symbol: "lbgal",
    name: "Pound per Gallon",
    type: "predefined",
  },
  {
    symbol: "ozgal",
    name: "Ounce per Gallon",
    type: "predefined",
  },
  {
    symbol: "b",
    name: "Bit",
    type: "predefined",
  },
  {
    symbol: "Kb",
    name: "Kilobit",
    type: "predefined",
  },
  {
    symbol: "Mb",
    name: "Megabit",
    type: "predefined",
  },
  {
    symbol: "Gb",
    name: "Gigabit",
    type: "predefined",
  },
  {
    symbol: "Tb",
    name: "Terabit",
    type: "predefined",
  },
  {
    symbol: "B",
    name: "Byte",
    type: "predefined",
  },
  {
    symbol: "KB",
    name: "Kilobyte",
    type: "predefined",
  },
  {
    symbol: "MB",
    name: "Megabyte",
    type: "predefined",
  },
  {
    symbol: "GB",
    name: "Gigabyte",
    type: "predefined",
  },
  {
    symbol: "TB",
    name: "Terabyte",
    type: "predefined",
  },
  {
    symbol: "ea",
    name: "Each",
    type: "predefined",
  },
  {
    symbol: "dz",
    name: "Dozen",
    type: "predefined",
  },
  {
    symbol: "Wh",
    name: "Watt-hour",
    type: "predefined",
  },
  {
    symbol: "mWh",
    name: "Milliwatt-hour",
    type: "predefined",
  },
  {
    symbol: "kWh",
    name: "Kilowatt-hour",
    type: "predefined",
  },
  {
    symbol: "MWh",
    name: "Megawatt-hour",
    type: "predefined",
  },
  {
    symbol: "GWh",
    name: "Gigawatt-hour",
    type: "predefined",
  },
  {
    symbol: "J",
    name: "Joule",
    type: "predefined",
  },
  {
    symbol: "kJ",
    name: "Kilojoule",
    type: "predefined",
  },
  {
    symbol: "N",
    name: "newton",
    type: "predefined",
  },
  {
    symbol: "kN",
    name: "Kilonewton",
    type: "predefined",
  },
  {
    symbol: "bf",
    name: "Pound-force",
    type: "predefined",
  },
  {
    symbol: "mHz",
    name: "millihertz",
    type: "predefined",
  },
  {
    symbol: "Hz",
    name: "hertz",
    type: "predefined",
  },
  {
    symbol: "kHz",
    name: "kilohertz",
    type: "predefined",
  },
  {
    symbol: "MHz",
    name: "megahertz",
    type: "predefined",
  },
  {
    symbol: "GHz",
    name: "gigahertz",
    type: "predefined",
  },
  {
    symbol: "THz",
    name: "terahertz",
    type: "predefined",
  },
  {
    symbol: "rpm",
    name: "rotation per minute",
    type: "predefined",
  },
  {
    symbol: "deg/s",
    name: "degree per second",
    type: "predefined",
  },
  {
    symbol: "rad/s",
    name: "radian per second",
    type: "predefined",
  },
  {
    symbol: "g",
    name: "Gram",
    type: "predefined",
  },
  {
    symbol: "shore-A",
    name: "shore A",
    type: "predefined",
  },
  {
    symbol: "shore-D",
    name: "shore D",
    type: "predefined",
  },
  {
    symbol: "%RH",
    name: "relative humidity",
    type: "predefined",
  },
  {
    symbol: "lx",
    name: "Lux",
    type: "predefined",
  },
  {
    symbol: "ft-cd",
    name: "Foot-candle",
    type: "predefined",
  },
  {
    symbol: "m-cd",
    name: "Meter-candle",
    type: "predefined",
  },
  {
    symbol: "µm",
    name: "Micrometer",
    type: "predefined",
  },
  {
    symbol: "mm",
    name: "Millimeter",
    type: "predefined",
  },
  {
    symbol: "cm",
    name: "Centimeter",
    type: "predefined",
  },
  {
    symbol: "m",
    name: "Meter",
    type: "predefined",
  },
  {
    symbol: "km",
    name: "Kilometer",
    type: "predefined",
  },
  {
    symbol: "x",
    name: "x",
    type: "predefined",
  },
  {
    symbol: "in",
    name: "Inch",
    type: "predefined",
  },
  {
    symbol: "yd",
    name: "Yard",
    type: "predefined",
  },
  {
    symbol: "ft-us",
    name: "US Survey Foot",
    type: "predefined",
  },
  {
    symbol: "ft",
    name: "Foot",
    type: "predefined",
  },
  {
    symbol: "mi",
    name: "Mile",
    type: "predefined",
  },
  {
    symbol: "mcg",
    name: "Microgram",
    type: "predefined",
  },
  {
    symbol: "mg",
    name: "Milligram",
    type: "predefined",
  },
  {
    symbol: "kg",
    name: "Kilogram",
    type: "predefined",
  },
  {
    symbol: "mt",
    name: "Metric Tonne",
    type: "predefined",
  },
  {
    symbol: "oz",
    name: "Ounce",
    type: "predefined",
  },
  {
    symbol: "lb",
    name: "Pound",
    type: "predefined",
  },
  {
    symbol: "t",
    name: "Ton",
    type: "predefined",
  },
  {
    symbol: "min/km",
    name: "Minute per kilometre",
    type: "predefined",
  },
  {
    symbol: "s/m",
    name: "Second per metre",
    type: "predefined",
  },
  {
    symbol: "min/mi",
    name: "Minute per mile",
    type: "predefined",
  },
  {
    symbol: "s/ft",
    name: "Second per foot",
    type: "predefined",
  },
  {
    symbol: "ppm",
    name: "Part-per Million",
    type: "predefined",
  },
  {
    symbol: "ppb",
    name: "Part-per Billion",
    type: "predefined",
  },
  {
    symbol: "ppt",
    name: "Part-per Trillion",
    type: "predefined",
  },
  {
    symbol: "ppq",
    name: "Part-per Quadrillion",
    type: "predefined",
  },
  {
    symbol: "W",
    name: "Watt",
    type: "predefined",
  },
  {
    symbol: "mW",
    name: "Milliwatt",
    type: "predefined",
  },
  {
    symbol: "kW",
    name: "Kilowatt",
    type: "predefined",
  },
  {
    symbol: "MW",
    name: "Megawatt",
    type: "predefined",
  },
  {
    symbol: "GW",
    name: "Gigawatt",
    type: "predefined",
  },
  {
    symbol: "Pa",
    name: "pascal",
    type: "predefined",
  },
  {
    symbol: "kPa",
    name: "kilopascal",
    type: "predefined",
  },
  {
    symbol: "MPa",
    name: "megapascal",
    type: "predefined",
  },
  {
    symbol: "hPa",
    name: "hectopascal",
    type: "predefined",
  },
  {
    symbol: "bar",
    name: "bar",
    type: "predefined",
  },
  {
    symbol: "mbar",
    name: "millibar",
    type: "predefined",
  },
  {
    symbol: "torr",
    name: "torr",
    type: "predefined",
  },
  {
    symbol: "kg/cm²",
    name: "Kg_cm_sq",
    type: "predefined",
  },
  {
    symbol: "mmHg",
    name: "Millimeters of mercury",
    type: "predefined",
  },
  {
    symbol: "mmWC",
    name: "Millimeters of water column",
    type: "predefined",
  },
  {
    symbol: "psi",
    name: "pound per square inch",
    type: "predefined",
  },
  {
    symbol: "ksi",
    name: "kilopound per square inch",
    type: "predefined",
  },
  {
    symbol: "VARh",
    name: "Volt-Ampere Reactive Hour",
    type: "predefined",
  },
  {
    symbol: "mVARh",
    name: "Millivolt-Ampere Reactive Hour",
    type: "predefined",
  },
  {
    symbol: "kVARh",
    name: "Kilovolt-Ampere Reactive Hour",
    type: "predefined",
  },
  {
    symbol: "MVARh",
    name: "Megavolt-Ampere Reactive Hour",
    type: "predefined",
  },
  {
    symbol: "GVARh",
    name: "Gigavolt-Ampere Reactive Hour",
    type: "predefined",
  },
  {
    symbol: "VAR",
    name: "Volt-Ampere Reactive",
    type: "predefined",
  },
  {
    symbol: "mVAR",
    name: "Millivolt-Ampere Reactive",
    type: "predefined",
  },
  {
    symbol: "kVAR",
    name: "Kilovolt-Ampere Reactive",
    type: "predefined",
  },
  {
    symbol: "MVAR",
    name: "Megavolt-Ampere Reactive",
    type: "predefined",
  },
  {
    symbol: "GVAR",
    name: "Gigavolt-Ampere Reactive",
    type: "predefined",
  },
  {
    symbol: "Ω",
    name: "Ohm",
    type: "predefined",
  },
  {
    symbol: "mΩ",
    name: "Milliohm",
    type: "predefined",
  },
  {
    symbol: "kΩ",
    name: "Kiloohm",
    type: "predefined",
  },
  {
    symbol: "MΩ",
    name: "Megaohm",
    type: "predefined",
  },
  {
    symbol: "GΩ",
    name: "Gigaohm",
    type: "predefined",
  },
  {
    symbol: "dB",
    name: "Decibel",
    type: "predefined",
  },
  {
    symbol: "dBA",
    name: "Decibel A-weighted",
    type: "predefined",
  },
  {
    symbol: "SPL",
    name: "Sound Pressure Level",
    type: "predefined",
  },
  {
    symbol: "m/s",
    name: "Metre per second",
    type: "predefined",
  },
  {
    symbol: "km/h",
    name: "Kilometre per hour",
    type: "predefined",
  },
  {
    symbol: "m/h",
    name: "Mile per hour",
    type: "predefined",
  },
  {
    symbol: "knot",
    name: "Knot",
    type: "predefined",
  },
  {
    symbol: "ft/s",
    name: "Foot per second",
    type: "predefined",
  },
  {
    symbol: "fpm",
    name: "Foom per minute",
    type: "predefined",
  },
  {
    symbol: "°c",
    name: "degree Celsius",
    type: "predefined",
  },
  {
    symbol: "K",
    name: "degree Kelvin",
    type: "predefined",
  },
  {
    symbol: "F",
    name: "degree Fahrenheit",
    type: "predefined",
  },
  {
    symbol: "R",
    name: "degree Rankine",
    type: "predefined",
  },
  {
    symbol: "ns",
    name: "Nanosecond",
    type: "predefined",
  },
  {
    symbol: "mu",
    name: "Microsecond",
    type: "predefined",
  },
  {
    symbol: "ms",
    name: "Millisecond",
    type: "predefined",
  },
  {
    symbol: "s",
    name: "Second",
    type: "predefined",
  },
  {
    symbol: "min",
    name: "Minute",
    type: "predefined",
  },
  {
    symbol: "h",
    name: "Hour",
    type: "predefined",
  },
  {
    symbol: "d",
    name: "Day",
    type: "predefined",
  },
  {
    symbol: "week",
    name: "Week",
    type: "predefined",
  },
  {
    symbol: "month",
    name: "Month",
    type: "predefined",
  },
  {
    symbol: "year",
    name: "Year",
    type: "predefined",
  },
  {
    symbol: "Nm",
    name: "Newton-meter",
    type: "predefined",
  },
  {
    symbol: "kgfM",
    name: "Kilogram-force Meter",
    type: "predefined",
  },
  {
    symbol: "ftlb",
    name: "Foot-pound",
    type: "predefined",
  },
  {
    symbol: "inlb",
    name: "Inch-pound",
    type: "predefined",
  },
  {
    symbol: "V",
    name: "Volt",
    type: "predefined",
  },
  {
    symbol: "mV",
    name: "Millivolt",
    type: "predefined",
  },
  {
    symbol: "kV",
    name: "Kilovolt",
    type: "predefined",
  },
  {
    symbol: "µV",
    name: "Microvolt",
    type: "predefined",
  },
  {
    symbol: "mm3",
    name: "Cubic Millimeter",
    type: "predefined",
  },
  {
    symbol: "cm3",
    name: "Cubic Centimeter",
    type: "predefined",
  },
  {
    symbol: "µL",
    name: "Microliter",
    type: "predefined",
  },
  {
    symbol: "ml",
    name: "Millilitre",
    type: "predefined",
  },
  {
    symbol: "cl",
    name: "Centilitre",
    type: "predefined",
  },
  {
    symbol: "dl",
    name: "Decilitre",
    type: "predefined",
  },
  {
    symbol: "l",
    name: "Litre",
    type: "predefined",
  },
  {
    symbol: "kl",
    name: "Kilolitre",
    type: "predefined",
  },
  {
    symbol: "m3",
    name: "Cubic meter",
    type: "predefined",
  },
  {
    symbol: "km3",
    name: "Cubic kilometer",
    type: "predefined",
  },
  {
    symbol: "krm",
    name: "Matsked",
    type: "predefined",
  },
  {
    symbol: "tsk",
    name: "Tesked",
    type: "predefined",
  },
  {
    symbol: "msk",
    name: "Matsked",
    type: "predefined",
  },
  {
    symbol: "kkp",
    name: "Kaffekopp",
    type: "predefined",
  },
  {
    symbol: "glas",
    name: "Glas",
    type: "predefined",
  },
  {
    symbol: "kanna",
    name: "Kanna",
    type: "predefined",
  },
  {
    symbol: "tsp",
    name: "Total Suspended Particulate",
    type: "predefined",
  },
  {
    symbol: "Tbs",
    name: "Tbs",
    type: "predefined",
  },
  {
    symbol: "in3",
    name: "Cubic inch",
    type: "predefined",
  },
  {
    symbol: "fl-oz",
    name: "Fluid Ounce",
    type: "predefined",
  },
  {
    symbol: "cup",
    name: "Cup",
    type: "predefined",
  },
  {
    symbol: "pnt",
    name: "Pint",
    type: "predefined",
  },
  {
    symbol: "qt",
    name: "Quart",
    type: "predefined",
  },
  {
    symbol: "gal",
    name: "Gallon",
    type: "predefined",
  },
  {
    symbol: "ft3",
    name: "Cubic foot",
    type: "predefined",
  },
  {
    symbol: "yd3",
    name: "Cubic yard",
    type: "predefined",
  },
  {
    symbol: "mm3/s",
    name: "Cubic Millimeter per second",
    type: "predefined",
  },
  {
    symbol: "cm3/s",
    name: "Cubic Centimeter per second",
    type: "predefined",
  },
  {
    symbol: "ml/s",
    name: "Millilitre per second",
    type: "predefined",
  },
  {
    symbol: "cl/s",
    name: "Centilitre per second",
    type: "predefined",
  },
  {
    symbol: "dl/s",
    name: "Decilitre per second",
    type: "predefined",
  },
  {
    symbol: "l/s",
    name: "Litre per second",
    type: "predefined",
  },
  {
    symbol: "l/min",
    name: "Litre per minute",
    type: "predefined",
  },
  {
    symbol: "l/h",
    name: "Litre per hour",
    type: "predefined",
  },
  {
    symbol: "kl/s",
    name: "Kilolitre per second",
    type: "predefined",
  },
  {
    symbol: "kl/min",
    name: "Kilolitre per minute",
    type: "predefined",
  },
  {
    symbol: "kl/h",
    name: "Kilolitre per hour",
    type: "predefined",
  },
  {
    symbol: "m3/s",
    name: "Cubic meter per second",
    type: "predefined",
  },
  {
    symbol: "m3/min",
    name: "Cubic meter per minute",
    type: "predefined",
  },
  {
    symbol: "m3/h",
    name: "Cubic meter per hour",
    type: "predefined",
  },
  {
    symbol: "km3/s",
    name: "Cubic kilometer per second",
    type: "predefined",
  },
  {
    symbol: "tsp/s",
    name: "Total Suspended Particulate per second",
    type: "predefined",
  },
  {
    symbol: "Tbs/s",
    name: "TbS per second",
    type: "predefined",
  },
  {
    symbol: "in3/s",
    name: "Cubic inch per second",
    type: "predefined",
  },
  {
    symbol: "in3/min",
    name: "Cubic inch per minute",
    type: "predefined",
  },
  {
    symbol: "in3/h",
    name: "Cubic inch per hour",
    type: "predefined",
  },
  {
    symbol: "fl-oz/s",
    name: "Fluid Ounce per second",
    type: "predefined",
  },
  {
    symbol: "fl-oz/min",
    name: "Fluid Ounce per minute",
    type: "predefined",
  },
  {
    symbol: "fl-oz/h",
    name: "Fluid Ounce per hour",
    type: "predefined",
  },
  {
    symbol: "cup/s",
    name: "Cup per second",
    type: "predefined",
  },
  {
    symbol: "pnt/s",
    name: "Pint per second",
    type: "predefined",
  },
  {
    symbol: "pnt/min",
    name: "Pint per minute",
    type: "predefined",
  },
  {
    symbol: "pnt/h",
    name: "Pint per hour",
    type: "predefined",
  },
  {
    symbol: "qt/s",
    name: "Quart per second",
    type: "predefined",
  },
  {
    symbol: "gal/s",
    name: "Gallon per second",
    type: "predefined",
  },
  {
    symbol: "gal/min",
    name: "Gallon per minute",
    type: "predefined",
  },
  {
    symbol: "gal/h",
    name: "Gallon per hour",
    type: "predefined",
  },
  {
    symbol: "ft3/s",
    name: "Cubic foot per second",
    type: "predefined",
  },
  {
    symbol: "ft3/min",
    name: "Cubic foot per minute",
    type: "predefined",
  },
  {
    symbol: "ft3/h",
    name: "Cubic foot per hour",
    type: "predefined",
  },
  {
    symbol: "yd3/s",
    name: "Cubic yard per second",
    type: "predefined",
  },
  {
    symbol: "yd3/min",
    name: "Cubic yard per minute",
    type: "predefined",
  },
  {
    symbol: "yd3/h",
    name: "Cubic yard per hour",
    type: "predefined",
  },
  {
    symbol: "%FSD",
    name: "percent of full scale deflection ",
    type: "predefined",
  },
  {
    symbol: "inHg",
    name: "inches of mercury",
    type: "predefined",
  },
  {
    symbol: "s",
    name: "Second",
    type: "predefined",
  },
  {
    symbol: "%RDG",
    name: "reading",
    type: "custom",
  },
  {
    symbol: "%FS",
    name: "full scale",
    type: "custom",
  },
  {
    symbol: "Kgf",
    name: "kilogram-force",
    type: "custom",
  },
  {
    symbol: "nF",
    name: "Nanofarad",
    type: "custom",
  },
  {
    symbol: "mH",
    name: "megahertz",
    type: "custom",
  },
  {
    symbol: "pF",
    name: "Pressure Fall",
    type: "custom",
  },
  {
    symbol: "%",
    name: "percent",
    type: "custom",
  },
  {
    symbol: "HRC",
    name: "Hardness Rockwell C",
    type: "custom",
  },
  {
    symbol: "HLD",
    name: "Leeb Hardnes",
    type: "custom",
  },
  {
    symbol: "μH",
    name: "microhenry",
    type: "custom",
  },
  {
    symbol: "fs",
    name: "Full Scale",
    type: "custom",
  },
  {
    symbol: "Ratio",
    name: "Ratio",
    type: "custom",
  },
  {
    symbol: "shore-A",
    name: "shore-A",
    type: "custom",
  },
  {
    symbol: "shore-D",
    name: "shore-D",
    type: "custom",
  },
  {
    symbol: "Hrs",
    name: "Hrs",
    type: "custom",
  },
  {
    symbol: "Division",
    name: "Division",
    type: "custom",
  },
  {
    symbol: "count",
    name: "count",
    type: "custom",
  },
  {
    symbol: "Sp.Gr",
    name: "Sp.Gr",
    type: "custom",
  },
  {
    symbol: "g/cm⁴",
    name: "grams per cubic centimeter",
    type: "custom",
  },
  {
    symbol: "Minutes",
    name: "Minutes",
    type: "custom",
  },
  {
    symbol: "μF",
    name: "microfarad",
    type: "custom",
  },
  {
    symbol: "Trial",
    name: "Trial",
    type: "custom",
  },
  {
    symbol: "µm/°C",
    name: "micrometers per degree Celsius",
    type: "custom",
  },
  {
    symbol: "H",
    name: "Henry ",
    type: "custom",
  },
  {
    symbol: "Class",
    name: "Class",
    type: "custom",
  },
  {
    symbol: "A",
    name: "Ampere ",
    type: "custom",
  },
  {
    symbol: "X",
    name: "AReactance pere ",
    type: "custom",
  },
  {
    symbol: "Ld",
    name: "Load ",
    type: "custom",
  },
  {
    symbol: "mm/m",
    name: "millimeters per meter",
    type: "custom",
  },
  {
    symbol: "Hr",
    name: "Hour ",
    type: "custom",
  },
  {
    symbol: "FSD",
    name: "full scale deflection ",
    type: "custom",
  },
  {
    symbol: "ClassA",
    name: "ClassA",
    type: "custom",
  },
  {
    symbol: "H2o",
    name: "Water",
    type: "custom",
  },
  {
    symbol: "min",
    name: "Minute",
    type: "custom",
  },
  {
    symbol: "°",
    name: "Degree",
    type: "custom",
  },
  {
    symbol: "min of arc",
    name: "Minute of arc",
    type: "custom",
  },
  {
    symbol: "Mts",
    name: "Meters",
    type: "custom",
  },
  {
    symbol: "candela",
    name: "Candela",
    type: "custom",
  },
  {
    symbol: "HRA",
    name: "Rockwell hardness A",
    type: "custom",
  },
  {
    symbol: "HRBW",
    name: "Rockwell hardness B",
    type: "custom",
  },
  {
    symbol: "HR15N",
    name: "Rockwell hardness 15N",
    type: "custom",
  },
  {
    symbol: "HR30N",
    name: "Rockwell hardness 30N",
    type: "custom",
  },
  {
    symbol: "HR45N",
    name: "Rockwell hardness 45N",
    type: "custom",
  },
  {
    symbol: "HBW 5/750",
    name: "Brinell hardness 5/750",
    type: "custom",
  },
  {
    symbol: "HBW 10/500",
    name: "Brinell hardness scale 10/500",
    type: "custom",
  },
  {
    symbol: "HRBuu",
    name: "Hardness Rockwell B",
    type: "custom",
  },
  {
    symbol: "HBW 10/100",
    name: "Brinell hardness 10/100",
    type: "custom",
  },
  {
    symbol: "HBW 10/1000",
    name: "Brinell hardness 10/1000",
    type: "custom",
  },
  {
    symbol: "HBW 10-3000/1",
    name: "Brinell hardness 10-3000/1",
    type: "custom",
  },
  {
    symbol: "HBW 10/3000",
    name: "Brinell hardness 10/3000",
    type: "custom",
  },
  {
    symbol: "HV",
    name: "Hardness Vickers",
    type: "custom",
  },
  {
    symbol: "HV 5",
    name: "Vickers hardness 5",
    type: "custom",
  },
  {
    symbol: "HV 10",
    name: "Vickers hardness 10",
    type: "custom",
  },
  {
    symbol: "HV 10 -1",
    name: "Vickers hardness 10 -1",
    type: "custom",
  },
  {
    symbol: "HV 30-1",
    name: "Vickers hardness 30-1",
    type: "custom",
  },
  {
    symbol: "HV 30",
    name: "Vickers hardness 30",
    type: "custom",
  },
  {
    symbol: "HV 50",
    name: "Vickers hardness 50",
    type: "custom",
  },
  {
    symbol: "HV 50 - 1",
    name: "Vickers hardness 50 - 1",
    type: "custom",
  },
  {
    symbol: "HV 0.2",
    name: "Vickers hardness 0.2",
    type: "custom",
  },
  {
    symbol: "HV 0.3",
    name: "Vickers hardness 0.3",
    type: "custom",
  },
  {
    symbol: "HV 0.5",
    name: "Vickers hardness 0.5",
    type: "custom",
  },
  {
    symbol: "HV 1",
    name: "Vickers hardness 1",
    type: "custom",
  },
  {
    symbol: "HBW 2.5 - 187.5-ASTM",
    name: "Brinell hardness 2.5 - 187.5 ASTM",
    type: "custom",
  },
  {
    symbol: "HBW 10-3000 ASTM",
    name: "Brinell hardness 10-3000 ASTM",
    type: "custom",
  },
  {
    symbol: "HBW 2.5-187.5-IS-ISO",
    name: "Brinell hardness 2.5-187.5 IS ISO",
    type: "custom",
  },
  {
    symbol: "μm/m",
    name: "Micrometers per meter",
    type: "custom",
  },
  {
    symbol: "⌀",
    name: "Diameter",
    type: "custom",
  },
  {
    symbol: "arc of sec",
    name: "Arc second",
    type: "custom",
  },
  {
    symbol: "Ra",
    name: "Surface roughness average",
    type: "custom",
  },
  {
    symbol: "Rz",
    name: "Surface roughness maximum height",
    type: "custom",
  },
  {
    symbol: "Rmax",
    name: "Maximum roughness depth",
    type: "custom",
  },
  {
    symbol: "±",
    name: "Plus or minus",
    type: "custom",
  },
  {
    symbol: "sec of arc",
    name: "Second of Arc",
    type: "custom",
  },
  {
    symbol: "HBW",
    name: "Hardness Brinell",
    type: "custom",
  },
  {
    symbol: "mm/min",
    name: "Millimeter per Minute",
    type: "custom",
  },
  {
    symbol: "cd",
    name: "Candela",
    type: "custom",
  },
  {
    symbol: "mF",
    name: "Millifarad",
    type: "custom",
  },
  {
    symbol: "Ipm",
    name: "Inches per Minute",
    type: "custom",
  },
  {
    symbol: "L µm",
    name: "Micrometer",
    type: "custom",
  },
  {
    symbol: "μs",
    name: "Microsecond",
    type: "custom",
  },
  {
    symbol: "pH",
    name: "pH Level",
    type: "custom",
  },
  {
    symbol: "'",
    name: "quote1",
    type: "custom",
  },
  {
    symbol: '"',
    name: "quote2",
    type: "custom",
  },
  {
    symbol: "\\",
    name: "slash",
    type: "custom",
  },
  {
    symbol: "msec",
    name: "milliseconds",
    type: "custom",
  },
  {
    symbol: "Digit",
    name: "Digit",
    type: "custom",
  },
  {
    symbol: "lpm",
    name: "liters per minute",
    type: "custom",
  },
  {
    symbol: "x",
    name: "x",
    type: "custom",
  },
  {
    symbol: "'D' Scale",
    name: "D Scale",
    type: "custom",
  },
  {
    symbol: "μW/cm2",
    name: "microwatts per square centimeter",
    type: "custom",
  },
  {
    symbol: "w/m2",
    name: "watt per square metre",
    type: "custom",
  },
];

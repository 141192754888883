import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import Logo from "../image/sticker.jpeg";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import InfoIcon from "@mui/icons-material/Info";
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import MoreIcon from '@mui/icons-material/More';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Badge,
  Box,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Popover,
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import { NotificationList } from "../notification/Notification";
import MarkAttendanceModal from "../attendance/markAttendanceModal ";
import ResetPassword from "./resetPassword";
import { userDesignation } from "../../constants/masterUserConstants";

export const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [masterNested, setMasterNested] = React.useState(false);
  const [reportsNested, setReportsNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [MasterInOut, setMasterInOut] = React.useState(false);
  const [CommercialNested, setCommercialNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const [notificationsNo, setNotificationsNo] = React.useState(0);
  const [resposiveAnchor, setResposiveAnchor] = React.useState({
    left: false,
  });
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  // const selectedRouteInUpperCase = selectedRoute
  const [dropDown, setDropDown] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const designation = localStorage.getItem("designationIds")?.split(",") || [];


  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const handleClickDesignation = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverDesignationClosed = () => {
    setPopoverAnchor(null);
  };

  const handleDesignationSelect = (selectedId) => {
    const selectedDesignation = userDesignation.find(
      (designation) => designation.id === parseInt(selectedId)
    );
    if (selectedDesignation) {
      setSelectedDesignation(selectedDesignation.name);
      localStorage.setItem("designation",selectedDesignation.name)
      handlePopoverDesignationClosed();
    }
  };

  const storage_userName = localStorage.getItem("userName");
  const storage_userType = localStorage.getItem("type");
  const storage_modules = localStorage
    .getItem("modules")
    ?.split(",")
    .map((e) => e)
    .map((e) => Number(e));

  const handleClickDropDown = (event) => {
    setDropDown(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setDropDown(null);
  };

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('logInUser');
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.clear();
    navigate("/");
    window.location.reload(false);
  };
 let previouspath =sessionStorage.getItem("currentPath");
  const clearSearchFilterSessionStorage = (to)=>{

    const shouldConfirmNavigation = [
      '/datasheet/edit',
      '/srf/editSRF',
      '/srf/editSrf',
      '/master/masterEQP/editStandard',
      '/master/instrument/createInstrument',
      '/master/instrument/editInstrument',
      '/master/editStaticTable',
    ].some((route) => pathname.startsWith(route));

    if (shouldConfirmNavigation) {
      if (window.confirm('Are you sure you want to quit?')) {
        if(to !== previouspath){
          sessionStorage.removeItem("searchBy");
          sessionStorage.removeItem("searchKey");
          sessionStorage.removeItem("searched");
          sessionStorage.removeItem("currentPath");
        }
        navigate(to);
      }
    } else {
      if(to !== previouspath){
        sessionStorage.removeItem("searchBy");
        sessionStorage.removeItem("searchKey");
        sessionStorage.removeItem("searched");
        sessionStorage.removeItem("currentPath");
      }
       navigate(to);
    }
  };


  const hasAccessToModule = (key) => {
    key = Number(key);
    if (DISABLED_MODULE?.includes(key)) return false;
    if (`${storage_userType}` === "1") return true;
    else if (
      `${storage_userType}` === "2" ||
      `${storage_userType}` == "4" ||
      `${storage_userType}` == "3" ||
      `${storage_userType}` == "5" ||
      `${storage_userType}` == "6"
    ) {
      if (storage_modules?.includes(key)) return true;
      else return false;
    } else {
      if ([1].includes(key)) return true;
      else return false;
    }
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
    crossTab: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open3 = Boolean(anchorEl2);

  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    if (props.rolesInfo.roles && props.rolesInfo.roles?.length > 1)
      setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const nav_modules = [
    // {
    //   type: "item",
    //   component: Link,
    //   path: "/dashboard",
    //   icon: <DashboardIcon />,
    //   name: storage_userType === "3" ? "Home" : "Dashboard",
    //   moduleId: "1",
    // },
    {
      type: "item",
      component: Link,
      icon: <FormatListBulletedIcon />,
      name: "Master",
      moduleId: "2",
      onClick: (e) => setMasterNested(!masterNested),
      extra: masterNested ? <ExpandLess /> : <ExpandMore />,
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    setResposiveAnchor({ ...resposiveAnchor, [anchor]: open });
  };
  const sidebarListItems = () => (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        paddingTop: "0px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {hasAccessToModule("1") && !DISABLED_MODULE?.includes("Dashboard") && (
        <ListItemButton onClick={()=>clearSearchFilterSessionStorage("/dashboard")}>
          <ListItemIcon>
            <Tooltip
              title={storage_userType === "3" ? "Home" : "Dashboard"}
              arrow
              placement="right"
            >
              <DashboardIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText
              primary={storage_userType === "3" ? "Home" : "Dashboard"}
            />
          ) : (
            <Typography variant="p" component="div" align="left">
              {storage_userType === "3" ? "Home" : "Dashboard"}
            </Typography>
          )}
        </ListItemButton>
      )}

      {nav_modules.map((module) => {
        if (module.type === "item") {
          if (module.component)
            return (
              hasAccessToModule(module.moduleId) &&
              (module.to ? (
                <ListItemButton
                  component={module.component}
                  to={module.path}
                  onClick={module.onClick}
                >
                  <ListItemIcon>
                    <Tooltip title={module.name} arrow placement="right">
                      {module.icon}
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={module.name} />
                  ) : (
                    <ListItemText>
                      <Typography
                        variant="p"
                        component="div"
                        align="left"
                        style={{ fontSize: "14px" }}
                      >
                        {module.name}
                      </Typography>
                    </ListItemText>
                  )}
                  {module.extra || ""}
                </ListItemButton>
              ) : (
                <ListItemButton onClick={module.onClick}>
                  <ListItemIcon>
                    <Tooltip title={module.name} arrow placement="right">
                      {module.icon}
                    </Tooltip>
                  </ListItemIcon>
                  {!isMobile ? (
                    <ListItemText primary={module.name} />
                  ) : (
                    <ListItemText>
                      <Typography
                        variant="p"
                        component="div"
                        align="left"
                        style={{ fontSize: "14px" }}
                      >
                        {module.name}
                      </Typography>
                    </ListItemText>
                  )}
                  {module.extra || ""}
                </ListItemButton>
              ))
            );
        }
      })}
      {hasAccessToModule(2) && !DISABLED_MODULE?.includes("Master") && (
        <Collapse in={masterNested} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {hasAccessToModule(51) && !DISABLED_MODULE?.includes("Master EQP") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/masterEQP")}
              >
                <ListItemIcon>
                  <Tooltip title="Master EQP" arrow placement="right">
                    <BuildIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Master EQP"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Master EQP"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(52) && !DISABLED_MODULE?.includes("Instrument") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/instrument")}
              >
                <ListItemIcon>
                  <Tooltip title="Instrument" arrow placement="right">
                    <PrecisionManufacturingIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Instrument"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Instrument"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(53) && !DISABLED_MODULE?.includes("DUC Master") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/ducMasterList")}
              >
                <ListItemIcon>
                  <Tooltip title="DUC Master" arrow placement="right">
                    <AcUnitIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"DUC Master"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"DUC Master"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(54) && !DISABLED_MODULE?.includes("Discipline") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/discipline")}
              >
                <ListItemIcon>
                  <Tooltip title="Discipline" arrow placement="right">
                    <ArchitectureIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Discipline"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Discipline"}
                  </Typography>
                )}
              </ListItemButton>
            )}
              {hasAccessToModule(55) && !DISABLED_MODULE?.includes("Remark") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/remark")}
              >
                <ListItemIcon>
                  <Tooltip title="Remark" arrow placement="right">
                    <MoreIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Remark"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Remark"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(57) && !DISABLED_MODULE?.includes("Client") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/client")}
              >
                <ListItemIcon>
                  <Tooltip title="Client" arrow placement="right">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Client"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Client"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(58) && !DISABLED_MODULE?.includes("Units") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/unit/unitList")}
              >
                <ListItemIcon>
                  <Tooltip title="Units" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Units"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Units"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(59) && !DISABLED_MODULE?.includes("Table Template") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/template")}
              >
                <ListItemIcon>
                  <Tooltip title="Table Template" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Table Template"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Table Template"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(60) && !DISABLED_MODULE?.includes("Datasheet Template") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/datasheetTemplate")}
              >
                <ListItemIcon>
                  <Tooltip title="Datasheet Template" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Datasheet Template"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Datasheet Template"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(61) && !DISABLED_MODULE?.includes("Uncertainty") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/uncertinity/uncertinityList")} 
                >
                <ListItemIcon>
                  <Tooltip title="Uncertainty" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Uncertainty"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Uncertainty"}
                  </Typography>
                )}
              </ListItemButton>
            )}
            {hasAccessToModule(62) && !DISABLED_MODULE?.includes("Settings") && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/setting")}
                >
                <ListItemIcon>
                  <Tooltip title="Settings" arrow placement="right">
                    <TableChartIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Settings"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Settings"}
                  </Typography>
                )}
              </ListItemButton>
            )}
          </List>
        </Collapse>
      )}
      {hasAccessToModule(3) && !DISABLED_MODULE?.includes("SRF") && (
        <List component="div" disablePadding>
          <ListItemButton
            // sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/srf")}
          >
            <ListItemIcon>
              <Tooltip title="SRF" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>{" "}
            {!isMobile ? (
              <ListItemText primary={"SRF"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"SRF"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      )}

      {hasAccessToModule("4") && !DISABLED_MODULE?.includes("Datasheets") && (
        <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/datasheet")}
          id="editsrf_navbar_datasheet"
        >
          <ListItemIcon>
            <Tooltip title="Datasheets" arrow placement="right">
              <TableViewIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Datasheets"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Datasheets"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("5") && !DISABLED_MODULE?.includes("Certificates") && (
        <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/certificate")}
          id="datasheet-editdatasheet_navbar_certificate"
        >
          <ListItemIcon>
            <Tooltip title="Certificates" arrow placement="right">
              <ArticleIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Certificates"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Certificates"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("7") &&
        !DISABLED_MODULE?.includes("Courier Register") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/courier/courierList")}
          >
            <ListItemIcon>
              <Tooltip title="Courier Register" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Courier Register"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Courier Register"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {hasAccessToModule("8") && !DISABLED_MODULE?.includes("Expenses") && (
        <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/master/expense")}
        >
          <ListItemIcon>
            <Tooltip title="Expenses" arrow placement="right">
              <ArticleIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Expenses"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Expenses"}
            </Typography>
          )}
        </ListItemButton>
      )}

{hasAccessToModule("12") && !DISABLED_MODULE?.includes("Scheduler") && (
        <ListItemButton
        onClick={()=>clearSearchFilterSessionStorage("/scheduler")}
         >
          <ListItemIcon>
            <Tooltip title="Scheduler" arrow placement="right">
              <ArticleIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Scheduler"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Scheduler"}
            </Typography>
          )}
        </ListItemButton>
      )}


{hasAccessToModule("35") &&
        !DISABLED_MODULE?.includes("Reports") && (
          <ListItemButton
            // component={Link}
            // to="/standardInOutList"
            onClick={(e) => setReportsNested(!reportsNested)}
          >
             <ListItemIcon>
            <Tooltip title="Reports" arrow placement="right">
              <FormatListBulletedIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Reports"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Reports"}
              </Typography>
            </ListItemText>
          )}
          {reportsNested ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
           <Collapse in={reportsNested} timeout="auto" unmountOnExit>
           <List component="div" disablePadding>

        
           {hasAccessToModule("10") && !DISABLED_MODULE?.includes("Summary Report") && (
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/summaryReport")}
          >
            <ListItemIcon>
              <Tooltip title="Summary Report" arrow placement="right">
              <BookmarkIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Summary Report"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Summary Report"}
              </Typography>
            )}
          </ListItemButton>
           )}
           {hasAccessToModule("11") && !DISABLED_MODULE?.includes("History Card") && (
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/historyCard")}
          >
            <ListItemIcon>
              <Tooltip title="History Card" arrow placement="right">
              <BookmarkIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"History Card"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"History Card"}
              </Typography>
            )}
          </ListItemButton>
           )}
             {hasAccessToModule("9") && !DISABLED_MODULE?.includes("Work Report") && (
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/workReport")}
          >
            <ListItemIcon>
              <Tooltip title="Work Report" arrow placement="right">
              <ReceiptIcon />

              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Work Report"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Work Report"}
              </Typography>
            )}
          </ListItemButton>
             )}

{hasAccessToModule("36") && !DISABLED_MODULE?.includes("WCR Report") && (
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/wcrReport")}
          >
            <ListItemIcon>
              <Tooltip title="WCR Report" arrow placement="right">
              <BookmarkIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"WCR Report"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"WCR Report"}
              </Typography>
            )}
          </ListItemButton>
)}

          </List>
           </Collapse>

      {hasAccessToModule("14") && !DISABLED_MODULE?.includes("Commercial") && (
        <ListItemButton onClick={(e) => setCommercialNested(!CommercialNested)}>
          <ListItemIcon>
            <Tooltip title="Commercial" arrow placement="right">
              <FormatListBulletedIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Commercial"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Commercial"}
              </Typography>
            </ListItemText>
          )}
          {CommercialNested ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={CommercialNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/master/enquiry/enquiryList")}
          >
            <ListItemIcon>
              <Tooltip title="Enquiry" arrow placement="right">
                <NewspaperIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Enquiry"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Enquiry"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/enquiry/followUpReport")}
          >
            <ListItemIcon>
              <Tooltip title="Enquiry Followup" arrow placement="right">
                <BookmarkIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Enquiry Followup"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Enquiry Followup"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
                sx={{ pl: 4 }}
                onClick={()=>clearSearchFilterSessionStorage("/master/product")}
              >
                <ListItemIcon>
                  <Tooltip title="Price List" arrow placement="right">
                    <ArchitectureIcon />
                  </Tooltip>
                </ListItemIcon>
                {!isMobile ? (
                  <ListItemText primary={"Price List"} />
                ) : (
                  <Typography variant="p" component="div" align="left">
                    {"Price List"}
                  </Typography>
                )}
          </ListItemButton>                        
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/quotation")}
          >
            <ListItemIcon>
              <Tooltip title="Quotation" arrow placement="right">
                <RequestQuoteIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Quotation"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Quotation"}
              </Typography>
            )}
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/poAcceptance")}
          >
            <ListItemIcon>
              <Tooltip title="poAcceptance" arrow placement="right">
                <PodcastsIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"poAcceptance"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"poAcceptance"}
              </Typography>
            )}
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/viewInvoice")}
          >
            <ListItemIcon>
              <Tooltip title="Invoice" arrow placement="right">
                <ReceiptIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Invoice"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Invoice"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>

      {hasAccessToModule("17") && !DISABLED_MODULE?.includes("Payment") && (
        <ListItemButton onClick={(e) => setPaymentNested(!paymentNested)}>
          <ListItemIcon>
            <Tooltip title="Payment" arrow placement="right">
              <FormatListBulletedIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Payment"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Payment"}
              </Typography>
            </ListItemText>
          )}
          {paymentNested ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={paymentNested} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/paymentMaster")}
          >
            <ListItemIcon>
              <Tooltip title="Payment History" arrow placement="right">
                <BuildIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Payment History"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Payment History"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/accountStatement")}
          >
            <ListItemIcon>
              <Tooltip title="Account Statement" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Account Statement"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Account Statement"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/gstReport")}
          >
            <ListItemIcon>
              <Tooltip title="Gst Report" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Gst Report"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Gst Report"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>
      {hasAccessToModule("21") &&
        !DISABLED_MODULE?.includes("Master In/Out") && (
          <ListItemButton
            // component={Link}
            // to="/standardInOutList"
            onClick={(e) => setMasterInOut(!MasterInOut)}
          >
             <ListItemIcon>
            <Tooltip title="Payment" arrow placement="right">
              <FormatListBulletedIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Master In/Out"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Master In/Out"}
              </Typography>
            </ListItemText>
          )}
          {MasterInOut ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
           <Collapse in={MasterInOut} timeout="auto" unmountOnExit>
           <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/standardInOutList")}
          >
            <ListItemIcon>
              <Tooltip title="In/Out List" arrow placement="right">
              <ReceiptIcon />

              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"In/Out List"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"In/Out List"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/inOutReportList")}
          >
            <ListItemIcon>
              <Tooltip title="In/Out Report" arrow placement="right">
              <BookmarkIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"In/Out Report"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"In/Out Report"}
              </Typography>
            )}
          </ListItemButton>
          </List>
           </Collapse>
      {hasAccessToModule("28") &&
        !DISABLED_MODULE?.includes("Client Complaints") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/complaintsList")}
          >
            <ListItemIcon>
              <Tooltip title="Client Complaints" arrow placement="right">
                <DomainDisabledIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Client Complaints"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Client Complaints"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {hasAccessToModule("29") &&
        !DISABLED_MODULE?.includes("Client Feedback") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/feedbackList")}
          >
            <ListItemIcon>
              <Tooltip title="Client Feedback" arrow placement="right">
                <FeedbackIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Client Feedback"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Client Feedback"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {hasAccessToModule("25") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("User") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/master/users/userList")}
          >
            <ListItemIcon>
              <Tooltip title="User" arrow placement="right">
                <ManageAccountsIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"User"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"User"}
              </Typography>
            )}
          </ListItemButton>
        )}
      {hasAccessToModule("27") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Audit") && (
          <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/audit")}
          >
            <ListItemIcon>
              <Tooltip title="Audit" arrow placement="right">
                <SummarizeIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Audit"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Audit"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {hasAccessToModule("30") &&
        !DISABLED_MODULE?.includes("Request Form") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/requestFormList")}
          >
            <ListItemIcon>
              <Tooltip title="Request Form" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Request Form"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Request Form"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {hasAccessToModule("31") &&
        !DISABLED_MODULE?.includes("Document Upload") && (
          <ListItemButton
            onClick={()=>clearSearchFilterSessionStorage("/documentUpload")}
          >
            <ListItemIcon>
              <Tooltip title="Document Upload" arrow placement="right">
                <ArticleIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Document Upload"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Document Upload"}
              </Typography>
            )}
          </ListItemButton>
        )}

{hasAccessToModule("33") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Attendance") && (
          <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/attendance")}
           >
            <ListItemIcon>
              <Tooltip title="Attendance" arrow placement="right">
                <DocumentScannerIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Attendance"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Attendance"}
              </Typography>
            )}
          </ListItemButton>
        )}

      {hasAccessToModule("32") &&
        // `${storage_userType}` === "1" &&
        !DISABLED_MODULE?.includes("Help") && (
          <ListItemButton
          onClick={()=>clearSearchFilterSessionStorage("/help")}
          >
            <ListItemIcon>
              <Tooltip title="Help" arrow placement="right">
                <LiveHelpIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Help"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Help"}
              </Typography>
            )}
          </ListItemButton>
        )}
      <Collapse in={intermediateProcedure} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={()=>clearSearchFilterSessionStorage("/master/intermediateProcedure")}
          >
            <ListItemIcon>
              <Tooltip title="Intermediate Procedure" arrow placement="right">
                <BuildIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Intermediate Procedure" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/master/checkRecords"
            onClick={clearSearchFilterSessionStorage}
          >
            <ListItemIcon>
              <Tooltip title="Check Records" arrow placement="right">
                <PrecisionManufacturingIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Check Records" />
          </ListItemButton>
        </List>
      </Collapse>
      {isMobile && (
        <>
          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
            }}
          />
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="Notification" arrow placement="right">
                <IconButton
                  // onClick={handleClick2}
                  size="small"
                  sx={{ height: "27px", width: "27px" }}
                  aria-controls={open2 ? "Role" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  style={{ backgroundColor: "white", marginRight: "5px" }}
                  disabled
                >
                  <AccountCircleIcon style={{ color: "#1976d2" }} />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
            <Tooltip
              title="User (User Type)"
              style={{ textTransform: "uppercase" }}
            >
              <Typography
                variant="p"
                component="div"
                align="left"
                sx={{ fontSize: "90%" }}
              >
                {storage_userName} (
                {
                  ["admin", "Engineer", "Client", "Branch Admin","Lab Co-Ordinator", "Account Dept"][
                    Number(storage_userType) - 1
                  ]
                }
                )
              </Typography>
            </Tooltip>
          </ListItemButton>
          {hasAccessToModule("34") &&
        !DISABLED_MODULE?.includes("MarkAttendance") && (
            <MarkAttendanceModal isMobile={isMobile}/>
        )}
        <ResetPassword isMobile={isMobile}/>
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="Logout" arrow placement="right">
                <ExitToAppIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography
              variant="p"
              component="div"
              align="left"
              onClick={logout}
            >
              {"Logout"}
            </Typography>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Tooltip title="info" arrow placement="right">
                <InfoIcon />
              </Tooltip>
            </ListItemIcon>
            <Typography variant="p" component="div" align="left">
              {"LABCITY Software Version 2.0.0"}
            </Typography>
          </ListItemButton>
        </>
      )}
    </List>
  );
  const MobileList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <Typography variant="h6" gutterBottom component="div">
          Calibration
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {sidebarListItems()}
    </Box>
  );

  const handleDrawerToggle = () => {
    if (isMobile) {
      setResposiveAnchor({ ...resposiveAnchor, left: !resposiveAnchor.left });
    } else {
      // handleDrawerOpen();
      setOpen(!open);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense" style={{ paddingRight: "5px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            onClick={handleDrawerToggle}
            edge="start"
            // sx={{
            //   marginRight: "5px",
            //   ...(open && { display: "none" }),
            // }}
            sx={{
              marginRight: "5px",
              ...(!isMobile && open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
          <Tooltip title="Notification">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, mr: 1, height: "27px", width: "27px" }}
              aria-controls={open2 ? "available-roles" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white" }}
            >
              <Badge
                badgeStyle={{
                  top: 12,
                  right: 12,
                  backgroundColor: "white",
                  border: "2px solid black",
                  color: "white",
                  fontWright: 900,
                }}
                color="secondary"
                badgeContent={`${notificationsNo}`}
                secondary={true}
              >
                <NotificationsNoneIcon style={{ color: "#1976d2" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          {!isMobile && (
            <div
              style={{
                display: "flex",
                marginRight: "20px",
                cursor: "pointer",
                alignItems:"center",
                gap: "5px"
              }}             
            >
              <Tooltip style={{ textTransform: "uppercase",display: "flex", gap: "0px", alignItems: "center", }} onClick={handleClickDropDown}>
                <IconButton
                  // onClick={handleClick2}
                  size="small"
                  sx={{ ml: 2, height: "27px", width: "27px" }}
                  aria-controls={open2 ? "Role" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  style={{ backgroundColor: "white", marginRight: "5px" }}
                  disabled
                >
                  <AccountCircleIcon style={{ color: "#1976d2" }}/>
                </IconButton>
                <Typography
                  variant="p"
                  component="div"
                  align="left"
                  sx={{ fontSize: "90%" }}
                  // onClick={handleClickDropDown}
                >
                  {storage_userName} (
                  {
                    ["admin", "Engineer", "client", "branch admin","Lab Co-Ordinator", "Account Dept"][
                      Number(storage_userType) - 1
                    ]
                  }
                  )
                </Typography>
              </Tooltip>
              <Tooltip
                title="User (User Type)"
                style={{
                  textTransform: "uppercase",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                variant="p"
                component="div"
                align="left"
                sx={{ fontSize: "90%" }}
                onClick={handleClickDesignation}
              >
                {selectedDesignation ||
                  ((designation.length > 1) &&
                    userDesignation.find((d) => d.id === parseInt(designation[0]))?.name)}
              </Typography>
              </Tooltip>
              <Tooltip title="Role" style={{ textTransform: "uppercase" }}>
                {props.rolesInfo.roles?.length ? (
                  <Button
                    onClick={handleClick2}
                    size="small"
                    aria-controls={open2 ? "Role" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    style={{
                      color: "white",
                      // width: "193px",
                      justifyContent: "left",
                      textDecorationLine: "underline",
                    }}
                    disabled={
                      !props.rolesInfo.roles ||
                      props.rolesInfo.roles?.length === 1
                    }
                  >
                    ({props.rolesInfo.currentRole?.name || ""})
                  </Button>
                ) : (
                  ""
                )}
              </Tooltip>
            </div>
          )}
          <Popover
            open={Boolean(dropDown)}
            anchorEl={dropDown}
            onClose={handleCloseDropDown}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={{ padding: "10px" }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {/* <Button onClick={logout}>
                    <LibraryAddCheckIcon style={{ marginRight: "10px" }} />
                    Mark Attendance
                  </Button> */}
                       {hasAccessToModule("34") &&
        !DISABLED_MODULE?.includes("MarkAttendance") && (
                  <MarkAttendanceModal isMobile={isMobile}/>
        )}
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <ResetPassword isMobile={isMobile}/>
                  </Grid>
                )}
                {!isMobile && (
                  <Grid item>
                    <Button onClick={logout}>
                      <LogoutIcon style={{ marginRight: "10px" }} />
                      Logout
                    </Button>
                  </Grid>
                )}
                {!isMobile && (
                  <Grid item>
                    <Button onClick={handleButtonClick}>
                      <InfoIcon style={{ marginRight: "10px" }} />
                      Info
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </Popover>
  
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "24px",
                backgroundColor: "#fff",
                position: "fixed",
                top: "7%",
                right: "0px",
                width: "auto",
                textAlign: "right",
                boxSizing: "border-box",
                color: "#fff",
              }}
            >
              <span
                style={{
                  backgroundColor: "#000",
                  padding: "3px",
                }}
              >
                LABCITY Software version 2.0.0
              </span>
            </div>
          </Modal>
        </Toolbar>
      </AppBar>

      {!isMobile ? (
        <Drawer variant="permanent" open={open}>
          {open ? (
            <DrawerHeader sx={{ minHeight: "100px !important" }}>
              <img
                src={`${Logo}`}
                alt="logo"
                style={{
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                  height: "70px",
                  width: "60%",
                  margin: "auto",
                  textAlign: "center",
                }}
              />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
          ) : (
            <DrawerHeader sx={{ minHeight: "50px !important" }}></DrawerHeader>
          )}
          {sidebarListItems()}
        </Drawer>
      ) : null}

      <SwipeableDrawer
        anchor={"left"}
        open={resposiveAnchor["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {MobileList("left")}
      </SwipeableDrawer>

      <NotificationList
        anchorEl={anchorEl}
        open={open2}
        close={handleClose}
        notifyNumber={(n) => setNotificationsNo(n)}
        isMobile={isMobile}
      />
      <Menu
        anchorEl={anchorEl2}
        id="available-roles"
        open={open3}
        onClose={handleClose2}
        onClick={handleClose2}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {(props.rolesInfo?.roles || [])
          .filter((e) => (storage_userType == 3 ? e.client : !e.client))
          .map((role) => (
            <MenuItem
              onClick={() => {
                props.rolesInfo.setCurrentRole(role);
              }}
            >
              {role.name}
            </MenuItem>
          ))}
      </Menu>

      <Menu
        anchorEl={popoverAnchor}
        open={Boolean(popoverAnchor)}
        onClose={handlePopoverDesignationClosed}
        onClick={handlePopoverDesignationClosed}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {userDesignation
          .filter((d) => designation.includes(String(d.id)))
          .map((el) => (
            <MenuItem onClick={() => handleDesignationSelect(el.id)}>
              {el.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
